export const RECEIVE_USER = "RECEIVE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const CHANGE_LOADING_STATUS = "CHANGE_LOADING_STATUS";
export const RECEIVE_ORDER = "RECEIVE_ORDER";
export const RECEIVE_ORDERS_LIST = "RECEIVE_ORDERS_LIST";
export const RECEIVE_FLEET_LIST = "RECEIVE_FLEET_LIST";
export const RECEIVE_FLEET = "RECEIVE_FLEET";
export const RECEIVE_VEHICLE_LIST = "RECEIVE_VEHICLE_LIST";
export const RECEIVE_VEHICLE = "RECEIVE_VEHICLE";
export const RECEIVE_UPDATED_VEHICLE = "RECEIVE_UPDATED_VEHICLE";
